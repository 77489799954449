import React from 'react';
import styled from '@emotion/styled';

import { Content } from '@/components/molecules';
import { PrismicCookiesPage } from '@/domain/PrismicCookiesPage';

interface CookiesContainerProps {
    data: {
        prismicCookiesPage: PrismicCookiesPage;
    };
    lang: string;
}

const CookiesContainerRoot = styled.section``;

const CookiesContainer = ({ data, lang }: CookiesContainerProps): JSX.Element => {
    const { content } = data?.prismicCookiesPage?.data;

    const ContentData = {
        content: content?.raw
    }

    return (
        <CookiesContainerRoot>
            <Content {...ContentData} />
        </CookiesContainerRoot>
    );
};

export default CookiesContainer;
import React from 'react';
import { graphql } from 'gatsby';
import { SEO } from '@/components/atoms';
import { Layout } from '@/components/organisms';

import CookiesContainer from '@/containers/Cookies/CookiesContainer';
import { PrismicCookiesPage } from '@/domain/PrismicCookiesPage';
import { PageContext } from '@/domain/PageContext';

interface CookiesTemplateProps {
  data: {
    prismicCookiesPage: PrismicCookiesPage;
  };
  pageContext: PageContext;
}

export const query = graphql`
  query ($id: String!) {
    prismicCookiesPage(id: { eq: $id }) {
      uid
      lang
      alternate_languages {
        uid
        lang
      }
      data {
        meta_title
        meta_description
        content {
          raw
        }
      }
    }
  }
`;

const CookiesTemplate = ({
  data,
  pageContext,
}: CookiesTemplateProps): JSX.Element => {
  const { alternate } = pageContext;
  const { lang, uid, alternate_languages } = data?.prismicCookiesPage;
  const { meta_title, meta_description } = data?.prismicCookiesPage?.data;

  const selectlang = {
    lang,
    uid: uid,
    alternate_languages,
  };
  const seo = {
    title: meta_title,
    description: meta_description,
    lang: lang,
    url: `${lang}`,
  };
  const linkAlternate = alternate?.map((data) => ({
    lang: data?.lang,
    url: `${data?.lang}/${data?.uid}`,
  }));

  return (
    <Layout selectLang={selectlang}>
      <SEO
        lang={seo.lang}
        title={`${seo.title}`}
        url={seo.url}
        description={seo.description}
        alternate={linkAlternate}
      />
      <CookiesContainer data={data} lang={lang} />
    </Layout>
  );
};

export default CookiesTemplate;
